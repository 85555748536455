import { Directive, AfterViewInit, ElementRef } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[magnificPopup]'
})
export class MagnificPopupDirective implements AfterViewInit {
  
  config = {
    type: 'inline',

    fixedContentPos: false,
    fixedBgPos: true,

    overflowY: 'auto',

    closeBtnInside: true,
    preloader: false,

    midClick: true,
    removalDelay: 300,
    mainClass: 'my-mfp-zoom-in'
 }

  constructor(private _elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.init();
  }

  init() {
    $(this._elementRef.nativeElement).magnificPopup(this.config);   
  }

}
