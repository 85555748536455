import { Observable } from 'rxjs';
import { ShoppingService } from './../services/shopping-service.service';
import { Component, OnInit } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-category-boxes',
  templateUrl: './category-boxes.component.html',
  styleUrls: ['./category-boxes.component.css']
})
export class CategoryBoxesComponent implements OnInit {

  topCategories$: Observable<any[]>;
  loadingCategories: boolean = false;
  constructor(private shoppingService: ShoppingService) { }


  ngOnInit() {
    this.loadTopCategories();
  }
  
  loadTopCategories() {
    this.topCategories$ = this.shoppingService.topCategories();
  }
}
