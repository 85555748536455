import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[shareIcon]'
})
export class ShareIconDirective implements AfterViewInit{

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { }
  
  ngAfterViewInit(){
    this.init();
  }

  init() {
      
    var shareIconElement = this._elementRef.nativeElement;
    var renderer = this._renderer;

    renderer.listen(shareIconElement, 'click', (event) => {
      event.preventDefault();
		  $(shareIconElement).toggleClass('active');$(shareIconElement).toggleClass('sharing');
		  $(shareIconElement).children('.share-icon').toggleClass('sharing');
  	});
  };

}
