import { AppSettings } from './../app.settings';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CategoryService {

  constructor(private http:  HttpClient) { }
  
  getAllCategories() {
    return this.http.get<any>(`${AppSettings.API_ENDPOINT}/flyer/api/category`);
  }

  getAllStoreCategories() {
    return this.http.get<any>(`${AppSettings.API_ENDPOINT}/admin/api/category`);
  }

}
