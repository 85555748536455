import { OnInit, Component, Input, Output, EventEmitter, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'timer',
  template: '{{ formatedDate }}'
})
export class TimerComponent implements OnInit, OnDestroy {

  @Input('timer') timer: string;
  
  private _diff: number;

  private _days: number;

  private _hours: number;

  private _minutes: number;

  private _seconds: number;

  private _$count: Observable<any>;

  private subscription: Subscription;

  public formatedDate: string;

  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
      this._$count = interval(1000).pipe(
          map((x) => {
          this._diff = Date.parse(this.timer) - Date.parse(new Date().toString());
          }));

      this.subscription = this._$count.subscribe((x) => {
        if(this._diff > 0 ) {
          this._days = this.getDays(this._diff);
          this._hours = this.getHours(this._diff);
          this._minutes = this.getMinutes(this._diff);
          this._seconds = this.getSeconds(this._diff);
        }else {
          this._diff = 0;
          this.subscription.unsubscribe();
        }        
        this.formatedDate = ` ${this.getDays(this._diff)}d - ${this.getHours(this._diff)}h - ${this.getMinutes(this._diff)}m - ${this.getSeconds(this._diff)}s`;
      });    
  }

  getDays(t){
      return Math.floor( t/(1000*60*60*24) );
  }

  getHours(t){
      return Math.floor( (t/(1000*60*60)) % 24 );
  }

  getMinutes(t){
      return Math.floor( (t/1000/60) % 60 );
  }

  getSeconds(t){
      return Math.floor( (t/1000) % 60 );
  } 

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
