import { Observable } from 'rxjs';
import { ShoppingService } from './../services/shopping-service.service';
import { Component, OnInit } from '@angular/core';
declare var $ :any;

@Component({
  selector: 'app-store-most-visit-corousel',
  templateUrl: './store-most-visit-corousel.component.html',
	styleUrls: ['./store-most-visit-corousel.component.css'],
	providers: [ShoppingService]
})
export class StoreMostVisitCorouselComponent implements OnInit {
	
	topStores$:Observable<any[]>;
  slideConfig = {
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: true,
		arrows: true,
		responsive: [
		    {
		      breakpoint: 992,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 769,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
	  ]
	}; 
  
  constructor(private _shoppingService: ShoppingService) { }

  ngOnInit() {
   this.loadTopStores();
  }
	
	loadTopStores(){
		this.topStores$ = this._shoppingService.topStoresCarrousel();
	}
	
	onError(store) {
		store.image = "assets/images/store-default.png";
	}
}
