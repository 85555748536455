import { Observable } from 'rxjs';
import { SearchOffersFilterServiceService } from './../services/search-offers-filter-service.service';
import { CategoryService } from './../services/category-service.service';
import { Component, OnInit, NgZone, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormControl, NgForm } from "@angular/forms";
import {} from '@types/googlemaps'; 
import { Router, NavigationExtras } from '@angular/router';
import { OffersFilter } from '../models/offers-filter';

declare var $: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, AfterViewInit {
  
  searchControl: FormControl;
  latitude: number;
  longitude: number;
  categories$: Observable<any[]>;
  isCategoriesAvailable:boolean = false;
  filter:OffersFilter = {
    title: undefined,
    coordinates: [],
    category: undefined
  }

  @ViewChild("search")
  searchElementRef: ElementRef;
  
  @ViewChild("category")
  categorySelectElementRef: ElementRef; 

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private _categoryService: CategoryService, private _router: Router, private _searchOffersFilterServiceService: SearchOffersFilterServiceService) { }

  ngOnInit() {
    this.loadCategories();

    //create search FormControl
    this.searchControl = new FormControl();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          
          this.filter.address = place.formatted_address;
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });
  }
  
  ngAfterViewInit() {
    $.getScript('../assets/scripts/custom.js', () => {});
  }

  loadCategories() {
    this.categories$ = this._categoryService.getAllCategories();
  }

  onSearchSubmit() {
    if(this.longitude && this.latitude) 
      this.filter.coordinates = [this.latitude, this.longitude];
    this.filter.category = $(this.categorySelectElementRef.nativeElement).chosen().val() === "undefined" ? null: $(this.categorySelectElementRef.nativeElement).chosen().val();
    this._searchOffersFilterServiceService.addFilter(this.filter);
    this._router.navigate(['/ofertas']);
  }
}
