import { element } from 'protractor';
import { CategoryService } from './../services/category-service.service';
import { ShoppingService } from './../services/shopping-service.service';
import { SearchOffersFilterServiceService } from './../services/search-offers-filter-service.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, Subscription, interval } from 'rxjs';
import { OffersFilter } from '../models/offers-filter';
import { IOption } from 'ng-select';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { isEmpty }  from 'lodash';
import { PaginationInstance } from 'ngx-pagination';

declare var $:any;

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.css']
})
export class OfferListComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @ViewChild("search")
  searchElementRef: ElementRef;

  private _filterSubscribe: Subscription;
  private _radiusDefault:number = 50;
  private _filterOrderDefault:string = "LOWEST_PRICE";
  categories:IOption[];
  filterOrder: IOption[] = [{
   label: "Menor Preço",
   value: "LOWEST_PRICE" 
  },
  {
    label: "Maior Preço",
    value: "BIGGEST_PRICE" 
   },
   {
    label: "Mais próximo",
    value: "CLOSER" 
   }];
  filter:OffersFilter;
  offers$: Observable<any[]>;
  searchControl: FormControl;
  address: string;
  noFilterThreshold: boolean;
    
  private diff: number;
  private $counter: Observable<number>;
  private subscription: Subscription;

  //Pagination
  p: number = 1;
  totalElements: number;
  public config: PaginationInstance = {
    id: 'server',
    itemsPerPage: 10,
    currentPage: this.p,
    totalItems: this.totalElements
  };

  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Próximo'
  };

  constructor(private _renderer: Renderer2, 
    private _activateRoute: ActivatedRoute,
     private _searchOffersFilterServiceService:SearchOffersFilterServiceService, 
     private _shoppingService: ShoppingService,
      private _categoryService: CategoryService,
      private mapsAPILoader: MapsAPILoader,
      private ngZone: NgZone) { }

  ngOnInit() {
  
    this.loadCategories();
    this._filterSubscribe = this._searchOffersFilterServiceService.currentFilter.subscribe((filter) => {
      this.filter = filter;
      this.filter.radius = this._radiusDefault;
      this.filter.orderBy = this._filterOrderDefault;
      this.filter.category = this.filter.category || "ALL";;
      this.loadOffers(1);
    });

    //create search FormControl
    this.searchControl = new FormControl();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
    
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          this.address = place.formatted_address;
          if(isEmpty(this.filter.coordinates)){
            this.filter.coordinates = [];
            //set latitude, longitude
            this.filter.coordinates[0] = place.geometry.location.lat();
            this.filter.coordinates[1] = place.geometry.location.lng();
          }else {
            this.filter.coordinates[0] = place.geometry.location.lat();
            this.filter.coordinates[1] = place.geometry.location.lng();
          }
        });
      });
    });
  }
 
  onKeySearch(value: string) {
    if(!value) {
      this.address = value;
    }
  }
  
  loadOffers(page: number) {
    this.offers$ = this._shoppingService.getOffers(this.filter, page-1, this.config.itemsPerPage).pipe(
      tap(data => {
        this.config.totalItems = data.totalElements;
        this.config.currentPage = data.number+1;
      }),
      map(data => data.content)
    );
  }
  
  loadCategories() {
    this._categoryService.getAllCategories().subscribe((data) => {
      this.categories = data.map(element => {
        return {
          label: element.title,
          value: element.key
        }
      });
      this.categories.unshift({label: "Todas as categorias", value: "ALL"});
    });
  }

  ngOnDestroy(){
    this._filterSubscribe.unsubscribe();
    this._searchOffersFilterServiceService.addFilter(new OffersFilter());
  }

  ngAfterViewInit() {
    //this.triggers();
  }
  

  searchOffers() {
    if(!this.address) {
     this.filter.coordinates = undefined; 
    }
    this.filter.address = this.address;
    this.loadOffers(1);
  }
  
  onChange(event) {
    this.filter.radius = event.value;
  }

  onSelected(option: IOption) {
    this.filter.orderBy = option.value;
  }

  // triggers() {
  //   var renderer = this._renderer;
  //   var showMoreOptionsElement = this.showMoreOptions.nativeElement;
    
  //   renderer.listen(showMoreOptionsElement, 'click', (event) => {
  //     event.preventDefault();
	// 	  $('.more-search-options, .more-search-options-trigger').toggleClass('active');
	// 	  $('.more-search-options.relative').animate({height: 'toggle', opacity: 'toggle'}, 300);
  //   });
  // }
}
