import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  constructor(private router: Router) {}
  
  ngOnInit() {}
    
  ngAfterViewInit() {
    $.getScript('../assets/scripts/header.js', () => {});
  }

  isStoresRoute() {
    return this.router.url === "/lojas";
  }
}
