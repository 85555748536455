import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OffersFilter } from '../models/offers-filter';

@Injectable()
export class SearchOffersFilterServiceService {
  
  filterResource = new BehaviorSubject(new OffersFilter());
  currentFilter = this.filterResource.asObservable();

  constructor() { 
    console.log("SearchOffersFilterServiceService");
  }

  addFilter(filter: OffersFilter) {
    this.filterResource.next(filter);
  }
}
