export class OffersFilter {
    
    constructor(
        public title?: string,
        public coordinates?: number[],
        public category?: string,
        public radius?: number,
        public orderBy?: string,
        public address?: string
    ) { }
}