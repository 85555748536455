import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
declare var $ :any;

@Directive({
  selector: '[backToTop]'
})
export class BackToTopDirective implements AfterViewInit {
   
  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.backToTop();
  }

  backToTop() {
  
    var element = this._elementRef.nativeElement;
    var renderer = this._renderer;
    
    /*----------------------------------------------------*/
		/*  Back to Top
		/*----------------------------------------------------*/
    var pxShow = 600; // height on which the button will show
    var scrollSpeed = 500; // how slow / fast you want the button to scroll to top.

		$(window).scroll(function() {
		 if($(window).scrollTop() >= pxShow){
       renderer.addClass(element, 'visible');
		 } else {
       renderer.removeClass(element, 'visible');
		 }
		});
    
    renderer.listen(element.firstChild, 'click', (event) => {
      $('html, body').animate({scrollTop:0}, scrollSpeed);
		    return false;
      });
  }
}
