import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';
import { map, catchError } from 'rxjs/operators';

export interface IMessage {
  name?: string,
  email?: string,
  subject?: string,
  message?: string
}

@Injectable()
export class EmailService {
  private emailUrl = '/assets/contactemail.php';

  constructor(private httpClient: HttpClient) {}

  sendEmail(message: IMessage): Observable<IMessage> | any {
    return this.httpClient.post(this.emailUrl, message);
  }
}