import { AppSettings } from './../app.settings';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class ShoppingService {

  constructor(private http:  HttpClient) { }
  
  offersOfTheWeekCarrousel() {
    return this.http.get<any>(`${AppSettings.API_ENDPOINT}/flyer/api/shopping/offers-of-the-week`);
  }

  topStoresCarrousel() {
    return this.http.get<any>(`${AppSettings.API_ENDPOINT}/admin/api/shopping/top-stores`);
  }
  
  topCategories() {
    return this.http.get<any>(`${AppSettings.API_ENDPOINT}/flyer/api/shopping/top-categories`);
  }
  
  getOffers(filters, page, size) {
    return this.http.post<any>(`${AppSettings.API_ENDPOINT}/flyer/api/shopping/offers`, filters, {params: new HttpParams().set('page', page).set("size", size)});
  }

  getStoresByLocation(filters, page, size) {
    return this.http.post<any>(`${AppSettings.API_ENDPOINT}/admin/api/shopping/stores`, filters, {params: new HttpParams().set('page', page).set("size", size)}); 
  }
}
