import { IOption } from 'ng-select';
import { CategoryService } from './../services/category-service.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PaginationInstance } from 'ngx-pagination';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { OffersFilter } from './../models/offers-filter';
import { ShoppingService } from './../services/shopping-service.service';
import { Component, OnInit, AfterViewInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { isEmpty }  from 'lodash';
import { map, tap } from 'rxjs/operators';
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';

declare var $ :any;
declare var google: any;

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.css']
})
export class StoreListComponent implements OnInit, AfterViewInit {
	
	@ViewChild("search")
	searchElementRef: ElementRef;
	
	map: any;
  lat: number = 0;
	lng: number = 0;
  zoomControl: boolean = false;
  mapTypeControl: boolean = false;
  scaleControl: boolean = false;
  panControl: boolean = false;
  streetViewControl: boolean = false;
  gestureHandling: string = "cooperative";
	zoom: number = 2;
	categories$:Observable<IOption[]>;
	markers: marker[] = [];
	
	searchControl: FormControl;
	stores$: Observable<any[]>;

	filter:StoresFilter = {
		name: null,
		radius: 50,
		coordinates: [],
		category: "ALL",
	};

  //styles: any = [{'featureType': 'administrative', 'elementType': 'labels.text.fill', 'stylers': [{'color': '#444444'}]}, {'featureType': 'landscape', 'elementType': 'all', 'stylers': [{'color': '#f2f2f2'}]}, {'featureType': 'poi', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'road', 'elementType': 'all', 'stylers': [{'saturation': -100}, {'lightness': 45}]}, {'featureType': 'road.highway', 'elementType': 'all', 'stylers': [{'visibility': 'simplified'}]}, {'featureType': 'road.arterial', 'elementType': 'labels.icon', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'transit', 'elementType': 'all', 'stylers': [{'visibility': 'off'}]}, {'featureType': 'water', 'elementType': 'all', 'stylers': [{'color': '#4f595d'}, {'visibility': 'on'}]}];
  styles: any = [{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"23"}]},{"featureType":"poi.attraction","elementType":"geometry.fill","stylers":[{"color":"#f38eb0"}]},{"featureType":"poi.government","elementType":"geometry.fill","stylers":[{"color":"#ced7db"}]},{"featureType":"poi.medical","elementType":"geometry.fill","stylers":[{"color":"#ffa5a8"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#c7e5c8"}]},{"featureType":"poi.place_of_worship","elementType":"geometry.fill","stylers":[{"color":"#d6cbc7"}]},{"featureType":"poi.school","elementType":"geometry.fill","stylers":[{"color":"#c4c9e8"}]},{"featureType":"poi.sports_complex","elementType":"geometry.fill","stylers":[{"color":"#b1eaf1"}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":"100"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"},{"lightness":"100"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffd4a5"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffe9d2"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"weight":"3.00"}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"weight":"0.30"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"36"}]},{"featureType":"road.local","elementType":"labels.text.stroke","stylers":[{"color":"#e9e5dc"},{"lightness":"30"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"},{"lightness":"100"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#d2e7f7"}]}];
	
	//Pagination
  p: number = 1;
  totalElements: number;
  public config: PaginationInstance = {
    id: 'server',
    itemsPerPage: 6,
    currentPage: this.p,
    totalItems: this.totalElements
  };

  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Próximo'
  };

  constructor(private _shoppingService: ShoppingService,  private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone, private _categoryService: CategoryService) { }

  ngOnInit() {
		this.loadCategories();
		this.loadStores(1);
	
		//create search FormControl
		this.searchControl = new FormControl();
		
		this.geoLocate();
		
		 //load Places Autocomplete
		 this.mapsAPILoader.load().then(() => {

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
    
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
					 
          if(isEmpty(this.filter.coordinates)){
            this.filter.coordinates = [];
            //set latitude, longitude
            this.filter.coordinates[0] = place.geometry.location.lat();
            this.filter.coordinates[1] = place.geometry.location.lng();
          }else {
            this.filter.coordinates[0] = place.geometry.location.lat();
            this.filter.coordinates[1] = place.geometry.location.lng();
          }
        });
      });
    });
  }
  
  ngAfterViewInit(){
		this.jqueryCode();
  }
	
	onChange(event) {
    this.filter.radius = event.value;
	}

	onKeySearch(value: string) {
    if(!value) {
      this.filter.coordinates = [];
    }
  }
	
	loadCategories() {
		this.categories$ = this._categoryService.getAllStoreCategories().pipe(
			map((data) => {
				let array = [];
				array = data.map(element => {
					return {
						label: element.title,
						value: element.key
					}
				});
				array.unshift({label: "Todas as categorias", value: "ALL"});
				return array;
			})
		);
  }

	loadStores(page: number) {
		this.markers = [];
		this.stores$ = this._shoppingService.getStoresByLocation(this.filter, page-1, this.config.itemsPerPage).pipe(
      tap(data => {
        this.config.totalItems = data.totalElements;
        this.config.currentPage = data.number+1;
			}),
			tap(data => {
				const stores = data.content;
				stores.forEach(store => {
					store.salesPoint.forEach(point => {
						this.markers.push({
							name: point.name,
							address: point.address,
							city: point.city,
							number: point.number || null,
							phone: point.phone,
							lat: point.coordinate.latitude,
							lng: point.coordinate.longitude,
							icon: 'assets/images/point.png',
							store: {
								image: store.image,
								link: store.link
							}
						})
					});
				});
				if(stores.length > 0) {
					this.lat = data.content[0].salesPoint[0].coordinate.latitude;
					this.lng = data.content[0].salesPoint[0].coordinate.longitude;
				}
			}),
      map(data => data.content)
		);
	}

	searchStores() {
    this.loadStores(1);
	}
	
  mapReady(event: any) {
	this.map = event;
	
	var zoomControlDiv = document.createElement('div');
    var zoomControl = new ZoomControl(zoomControlDiv, this.map);

    function ZoomControl(controlDiv, map) {
		
		//   zoomControlDiv.index = 1;
		map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(zoomControlDiv);
		// Creating divs & styles for custom zoom control
		controlDiv.style.padding = '5px';
		controlDiv.className = "zoomControlWrapper";

		// Set CSS for the control wrapper
		var controlWrapper = document.createElement('div');
		controlDiv.appendChild(controlWrapper);

		// Set CSS for the zoomIn
		var zoomInButton = document.createElement('div');
		zoomInButton.className = "custom-zoom-in";
		controlWrapper.appendChild(zoomInButton);

		// Set CSS for the zoomOut
		var zoomOutButton = document.createElement('div');
		zoomOutButton.className = "custom-zoom-out";
		controlWrapper.appendChild(zoomOutButton);

		// Setup the click event listener - zoomIn
		google.maps.event.addDomListener(zoomInButton, 'click', function() {
		map.setZoom(map.getZoom() + 1);
		});

		// Setup the click event listener - zoomOut
		google.maps.event.addDomListener(zoomOutButton, 'click', function() {
		map.setZoom(map.getZoom() - 1);
		});
	}
  }
	
  geoLocate() {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(position => {
		  this.lat = position.coords.latitude;
		  this.lng = position.coords.longitude;
		});
	  } else {
			this.lat = -7.159939;
			this.lng = -34.840837;
	  }
  }
  jqueryCode() {
		/*----------------------------------------------------*/
		/* Panel Dropdown
		/*----------------------------------------------------*/
		function close_panel_dropdown() {
			$('.panel-dropdown').removeClass("active");
			$('.fs-inner-container.content').removeClass("faded-out");
		}
	
		$('.panel-dropdown a').on('click', function(e) {
	
			if ( $(this).parent().is(".active") ) {
				close_panel_dropdown();
			} else {
				close_panel_dropdown();
				$(this).parent().addClass('active');
				$('.fs-inner-container.content').addClass("faded-out");
			}
	
			e.preventDefault();
		});
	
		// Apply / Close buttons
		$('.panel-buttons button').on('click', function(e) {
			$('.panel-dropdown').removeClass('active');
			$('.fs-inner-container.content').removeClass("faded-out");
		});
	
		// Closes dropdown on click outside the conatainer
		var mouse_is_inside = false;
	
		$('.panel-dropdown').hover(function(){
			mouse_is_inside=true;
		}, function(){
			mouse_is_inside=false;
		});
	
		$("body").mouseup(function(){
			if(! mouse_is_inside) close_panel_dropdown();
		});
	} 
}


class StoresFilter {
    
	constructor(
			public name?: string,
			public coordinates?: number[],
			public category?: string,
			public radius?: number
		) { }
}

// just an interface for type safety.
interface marker {
	name: string;
	address: string;
	city: string;
	number?: string;
	phone: string;
	lat: number;
	lng: number;
	icon: string;
	store: object;
}
