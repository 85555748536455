import { OfferListComponent } from './offer-list/offer-list.component';
import { HomeContentComponent } from './home-content/home-content.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoreListComponent } from './store-list/store-list.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '',  component: HomeComponent, 
  children: [
    {path: '', component: HomeContentComponent},
    {path: 'ofertas', component: OfferListComponent},
    {path: 'contato', component: ContactComponent}
  ] },
  { path: 'lojas', component: StoreListComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
