import { Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ShoppingService } from '../services/shopping-service.service';

declare var $ :any;

@Component({
  selector: 'app-category-carousel',
  templateUrl: './category-carousel.component.html',
	styleUrls: ['./category-carousel.component.css']
})
export class CategoryCarouselComponent implements OnInit, AfterViewInit {

	offers$: Observable<any[]>;
  slideConfig = {
	  centerMode: true,
	  centerPadding: '15%',
	  slidesToShow: 3,
		dots: true,
		
	  arrows: false,
	  responsive: [
		{
		  breakpoint: 1441,
		  settings: {
				centerPadding: '10%',
				dots: true,
		    slidesToShow: 3
		  }
		},
		{
		  breakpoint: 1025,
		  settings: {
		    centerPadding: '10%',
		    slidesToShow: 2,
		  }
		},
		{
		  breakpoint: 767,
		  settings: {
		    centerPadding: '10%',
		    slidesToShow: 1
		  }
		}
	  ]
	}; 
  
  constructor(private _shoppingService: ShoppingService) { }

	loadOffers(){
		this.offers$ = this._shoppingService.offersOfTheWeekCarrousel();
	}


  ngOnInit() {
		this.loadOffers();
	}

  ngAfterViewInit() {
		
	}
}
