import { element } from 'protractor';
import { Directive, ElementRef, AfterViewInit } from '@angular/core';
declare var $: any;
/*----------------------------------------------------*/
/*  Chosen Plugin
/*----------------------------------------------------*/
  
@Directive({
  selector: '[chosenSelect]'
})
export class ChosenSelectDirective implements AfterViewInit {
  
  config = {
    '.chosen-select'           : {disable_search_threshold: 10, width:"100%"},
    '.chosen-select-deselect'  : {allow_single_deselect:true, width:"100%"},
    '.chosen-select-no-single' : {disable_search_threshold:100, width:"100%"},
    '.chosen-select-no-single.no-search' : {disable_search_threshold:10, width:"100%"},
    '.chosen-select-no-results': {no_results_text:'Oops, nothing found!'},
    '.chosen-select-width'     : {width:"95%"}
  };

  constructor(private _elementRef: ElementRef) { }
  
  ngAfterViewInit() {
    this.init();
  }
  
  init() {

    var element = this._elementRef.nativeElement;
    var selector = element.dataset.class;
      
    if(this.config.hasOwnProperty(selector)) {
      $(element).chosen(this.config[selector]);
    }
  }

}
