import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[starRating]'
})
export class StarRatingDirective implements AfterViewInit  {

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { 
  }

  ngAfterViewInit(){
    this.starRating();
  }

  /*  Star Rating
	/*--------------------------*/
	starRating() {
    var ratingElem = this._elementRef.nativeElement;
    var dataRating = ratingElem.dataset.rating;
    var renderer = this._renderer;
    
    // Rating Stars Output
    function starsOutput(firstStar, secondStar, thirdStar, fourthStar, fifthStar) {
      
      const firstSpan = renderer.createElement('span');
      _.each(firstStar, (value) => {
        renderer.addClass(firstSpan, value);
      });
      
  
      const secondSpan = renderer.createElement('span');
      _.each(secondStar, (value) => {
        renderer.addClass(secondSpan, value);
      });

      const thirdSpan = renderer.createElement('span');
      _.each(thirdStar, (value) => {
        renderer.addClass(thirdSpan, value);
      });
        
      const fourthSpan = renderer.createElement('span');
      _.each(fourthStar, (value) => {
        renderer.addClass(fourthSpan, value);
      });
      
      const fifthSpan = renderer.createElement('span');
      _.each(fifthStar, (value) => {
        renderer.addClass(fifthSpan, value);
      });

      renderer.appendChild(ratingElem, firstSpan);
      renderer.appendChild(ratingElem, secondSpan);
      renderer.appendChild(ratingElem, thirdSpan);
      renderer.appendChild(ratingElem, fourthSpan);
      renderer.appendChild(ratingElem, fifthSpan);
    }
        
    // Rules
    if (dataRating >= 4.75) {
      starsOutput(['star'],['star'],['star'],['star'],['star']);
    } else if (dataRating >= 4.25) {
      starsOutput(['star'],['star'],['star'],['star'],['star', 'half']);
    } else if (dataRating >= 3.75) {
      starsOutput(['star'],['star'],['star'],['star'],['star', 'empty']);
    } else if (dataRating >= 3.25) {
      starsOutput(['star'],['star'],['star'],['star', 'half'],['star', 'empty']);
    } else if (dataRating >= 2.75) {
      starsOutput(['star'],['star'],['star'],['star','empty'],['star', 'empty']);
    } else if (dataRating >= 2.25) {
      starsOutput(['star'],['star'],['star', 'half'],['star', 'empty'], ['star', 'empty']);
    } else if (dataRating >= 1.75) {
      starsOutput(['star'],['star'],['star', 'empty'], ['star', 'empty'], ['star', 'empty']);
    } else if (dataRating >= 1.25) {
      starsOutput(['star'], ['star', 'half'], ['star', 'empty'], ['star', 'empty'],['star', 'empty']);
    } else if (dataRating < 1.25) {
      starsOutput(['star', 'empty'],['star', 'empty'], ['star', 'empty'], ['star', 'empty'],['star', 'empty']);
    }
	}

}
