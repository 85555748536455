import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[inlineCss]'
})
export class InlineCssDirective implements AfterViewInit{

  constructor( private _elementRef: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit () {
    this.inlineCss();
  }

  inlineCss(){
    
    var element = this._elementRef.nativeElement;
    
    var attrImageBG =  element.dataset.backgroundImage;
    var attrColorBG = element.dataset.backgroundColor;
    
    if(attrImageBG !== undefined) {
      this._renderer.setStyle(element, 'background-image', `url(${attrImageBG})`);
    }

    if(attrColorBG !== undefined) {
      this._renderer.setStyle(element, 'background', attrColorBG);
    }
  }
}
