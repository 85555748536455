import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
declare var $:any;

@Directive({
  selector: '[shareMenu]'
})
export class ShareMenuDirective implements AfterViewInit {

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { }
  
  ngAfterViewInit(){
    this.init();
  }

  init() {
      
    var shareMenuElement = this._elementRef.nativeElement;
    var renderer = this._renderer;

    renderer.listen(shareMenuElement, 'click', (event) => {
		  $(shareMenuElement).toggleClass('active');
  	});
  };
}
