import { EmailService } from './services/email.service';
import { CategoryService } from './services/category-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { CategoryCarouselComponent } from './category-carousel/category-carousel.component';
import { HeaderComponent } from './header/header.component';
import { BannerComponent } from './banner/banner.component';
import { InfoComponent } from './info/info.component';
import { CategoryBoxesComponent } from './category-boxes/category-boxes.component';
import { StoreMostVisitCorouselComponent } from './store-most-visit-corousel/store-most-visit-corousel.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreListComponent } from './store-list/store-list.component';
import { SlickModule } from 'ngx-slick';
import { StarRatingDirective } from './shared/star-rating.directive';
import { InlineCssDirective } from './shared/inline-css.directive';
import { BackToTopDirective } from './shared/back-to-top.directive';
import { ChosenSelectDirective } from './shared/chosen-select.directive';
import { MagnificPopupDirective } from './shared/magnific-popup.directive';

import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { OfferListComponent } from './offer-list/offer-list.component';
import { HomeContentComponent } from './home-content/home-content.component';
import { ShareMenuDirective } from './shared/share-menu.directive';
import { ShareIconDirective } from './shared/share-icon.directive';
import { LikeIconDirective } from './shared/like-icon.directive';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule } from '@angular/common/http';

import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShoppingService } from './services/shopping-service.service';
import { SearchOffersFilterServiceService } from './services/search-offers-filter-service.service';
import {MatSliderModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SelectModule} from 'ng-select';
import {NgxPaginationModule} from 'ngx-pagination';
import {AgmJsMarkerClustererModule} from '@agm/js-marker-clusterer';
import { AgmOverlays } from "agm-overlays"
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { ShareModule } from '@ngx-share/core';
import { TimerComponent } from './shared/timer.component';
import { ToastrModule, ToastNoAnimation,
  ToastNoAnimationModule  } from 'ngx-toastr';
import { LaddaModule } from 'angular2-ladda';
import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';
 
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    CategoryCarouselComponent,
    HeaderComponent,
    BannerComponent,
    InfoComponent,
    CategoryBoxesComponent,
    StoreMostVisitCorouselComponent,
    HomeComponent,
    StoreListComponent,
    StarRatingDirective,
    InlineCssDirective,
    BackToTopDirective,
    ChosenSelectDirective,
    MagnificPopupDirective,
    OfferListComponent,
    HomeContentComponent,
    ShareMenuDirective,
    ShareIconDirective,
    LikeIconDirective,
    ContactComponent,
    TimerComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickModule.forRoot(),
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYmU6GCYvKgYTMdjNpIjBOIsjp8VaWVbI',
      libraries: ["places"],
      language: "pt"
    }),
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    SelectModule,
    NgxPaginationModule,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    AgmOverlays,
    ShareModule.forRoot(),
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation
    }),
    LaddaModule.forRoot({
      style: "slide-left"
  })
  ],
  providers: [
    CategoryService,
    ShoppingService,
    SearchOffersFilterServiceService,
    EmailService,
    Location, {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
