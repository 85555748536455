import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
declare var $:any;

@Directive({
  selector: '[likeIcon]'
})
export class LikeIconDirective implements AfterViewInit {

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { }
  
  ngAfterViewInit(){
    this.init();
  }

  init() {
      
    var likeIconElement = this._elementRef.nativeElement;
    var renderer = this._renderer;

    renderer.listen(likeIconElement, 'click', (event) => {
      event.preventDefault();
		  $(likeIconElement).toggleClass('liked');
		  $(likeIconElement).children('.like-icon').toggleClass('liked');
  	});
  };
}
