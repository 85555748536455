import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { IMessage, EmailService } from './../services/email.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
declare var google: any;
declare var $:any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  
  validation_messages = {
    'name': [
      { type: 'required', message: 'Este campo é obrigatório' }
    ],
    'email': [
      { type: 'required', message: 'Este campo é obrigatório' },
      { type: 'pattern', message: 'E-mail inválido, favor insira um válido.' }
    ],
    'subject': [
      { type: 'required', message: 'Este campo é obrigatório' }
    ],
    'message': [
      { type: 'required', message: 'Este campo é obrigatório' }
    ]
    }


  singleMap: any;
  myLatlng = {lng: -34.838449, lat:  -7.090099};
  zoom: number = 15;
  lat: number = -7.090099;
  lng: number = -34.838449;
  scrollwheel:boolean = false;
  zoomControl: boolean = false;
  mapTypeControl: boolean = false;
  scaleControl: boolean = false;
  panControl: boolean = false;
  navigationControl: boolean = false;
  streetViewControl: boolean = false;
  styles:any = [{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"23"}]},{"featureType":"poi.attraction","elementType":"geometry.fill","stylers":[{"color":"#f38eb0"}]},{"featureType":"poi.government","elementType":"geometry.fill","stylers":[{"color":"#ced7db"}]},{"featureType":"poi.medical","elementType":"geometry.fill","stylers":[{"color":"#ffa5a8"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#c7e5c8"}]},{"featureType":"poi.place_of_worship","elementType":"geometry.fill","stylers":[{"color":"#d6cbc7"}]},{"featureType":"poi.school","elementType":"geometry.fill","stylers":[{"color":"#c4c9e8"}]},{"featureType":"poi.sports_complex","elementType":"geometry.fill","stylers":[{"color":"#b1eaf1"}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":"100"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"},{"lightness":"100"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffd4a5"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffe9d2"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"weight":"3.00"}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"weight":"0.30"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"36"}]},{"featureType":"road.local","elementType":"labels.text.stroke","stylers":[{"color":"#e9e5dc"},{"lightness":"30"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"},{"lightness":"100"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#d2e7f7"}]}]
  contactForm;
  submitted = false;
  isSending = false;

  message: IMessage = {};

  subscription: Subscription;

  constructor(private _emailService: EmailService, private _toastr: ToastrService) { }

  ngOnInit() {
   this.contactForm = new FormGroup({
     name: new FormControl('', Validators.required),
     email: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$')]),
     subject: new FormControl('', Validators.required),
     message: new FormControl('', Validators.required)
   });
  }
  
  onSubmitContact() {
    this.submitted = true;
    
    if(this.contactForm.valid) {
      this.message = this.contactForm.value;
      this.isSending = true;
      this.subscription = this._emailService.sendEmail(this.message).subscribe(res => {
        this._toastr.success('Em breve entraremos em contato', 'Mensagem enviada com sucesso!'); 
        this.contactForm.reset();
        this.isSending = false;
      }, error => {
        this._toastr.error('Não foi possível enviar sua mensagem', 'Tente novamente!');
        this.isSending = false;
      });
    }
  }

  mapReady(event: any) {
    this.singleMap = event;
    this.customElements(this.singleMap);
    
  }
  
  onDestroy() {
    this.subscription.unsubscribe();
  }

  streetView(event) {
    event.preventDefault();
    this.singleMap.getStreetView().setOptions({visible:true, position:this.myLatlng});
  }

  customElements(singleMap) {
    // Custom zoom buttons
    var zoomControlDiv = document.createElement('div');
    var zoomControl = new ZoomControl(zoomControlDiv, singleMap);

    function ZoomControl(controlDiv, singleMap) {

      // zoomControlDiv.index = 1;
      singleMap.controls[google.maps.ControlPosition.RIGHT_CENTER].push(zoomControlDiv);

      controlDiv.style.padding = '5px';

      var controlWrapper = document.createElement('div');
      controlDiv.appendChild(controlWrapper);

      var zoomInButton = document.createElement('div');
      zoomInButton.className = "custom-zoom-in";
      controlWrapper.appendChild(zoomInButton);

      var zoomOutButton = document.createElement('div');
      zoomOutButton.className = "custom-zoom-out";
      controlWrapper.appendChild(zoomOutButton);

      google.maps.event.addDomListener(zoomInButton, 'click', function() {
        singleMap.setZoom(singleMap.getZoom() + 1);
      });

      google.maps.event.addDomListener(zoomOutButton, 'click', function() {
        singleMap.setZoom(singleMap.getZoom() - 1);
      });

    }
  }
}

